.action-button {
    position: relative;
    display: flex;
    gap: 0.75ch;
    justify-content: center;
    align-items: center;
    line-height: 1.25;
    font-size: var(--base-font-size-px);
    font-family: "Source Sans 3 VF", sans-serif;
    font-weight: 500;
    letter-spacing: 0.02ch;
    padding: 0.25em 0.625em;
    color: var(--color-text-neutral-quiet-action-button);
    background-color: var(--color-background-neutral-quiet-action-button);
    border-radius: 4px;
    white-space: nowrap;
    user-select: none;
    border: none;
    cursor: pointer;
    clip-path: inset(-1px);
    transition: 0.05s ease-in;
    transition-property: background-color, clip-path;

    &:hover {
        background-color: var(
            --color-background-neutral-quiet-action-button-hover
        );
        transition: 0.1s ease-out;
        transition-property: background-color, clip-path;
    }

    &:active {
        background-color: var(
            --color-background-neutral-quiet-action-button-active
        );
        clip-path: inset(1px round 4px);
    }

    &:focus {
        /* Override common button outline style set in zulip.css and dark_theme.css */
        outline: none;
    }

    &:focus-visible {
        /* Override common button outline style set in zulip.css and dark_theme.css */
        outline: 1px solid var(--color-outline-focus) !important;
        outline-offset: 2px;
        clip-path: inset(-3px);
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
    }
}

.action-button-label {
    max-width: 32ch;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Action buttons -- Neutral Intent */
.action-button-primary-neutral {
    color: var(--color-text-neutral-primary-action-button);
    background-color: var(--color-background-neutral-primary-action-button);

    &:hover {
        background-color: var(
            --color-background-neutral-primary-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-neutral-primary-action-button-active
        );
    }
}

.action-button-quiet-neutral {
    color: var(--color-text-neutral-quiet-action-button);
    background-color: var(--color-background-neutral-quiet-action-button);
    box-shadow: 0 0 0.5px 0.5px var(--color-inner-shadow-action-button) inset;

    &:hover {
        background-color: var(
            --color-background-neutral-quiet-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-neutral-quiet-action-button-active
        );
    }
}

.action-button-borderless-neutral {
    color: var(--color-text-neutral-borderless-action-button);
    background-color: transparent;

    &:hover {
        background-color: var(
            --color-background-neutral-borderless-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-neutral-borderless-action-button-active
        );
    }
}

/* Action buttons -- Brand Intent */
.action-button-primary-brand {
    color: var(--color-text-brand-primary-action-button);
    background-color: var(--color-background-brand-primary-action-button);

    &:hover {
        background-color: var(
            --color-background-brand-primary-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-brand-primary-action-button-active
        );
    }
}

.action-button-quiet-brand {
    color: var(--color-text-brand-quiet-action-button);
    background-color: var(--color-background-brand-quiet-action-button);
    box-shadow: 0 0 0.5px 0.5px var(--color-inner-shadow-action-button) inset;

    &:hover {
        background-color: var(
            --color-background-brand-quiet-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-brand-quiet-action-button-active
        );
    }
}

.action-button-borderless-brand {
    color: var(--color-text-brand-borderless-action-button);
    background-color: transparent;

    &:hover {
        background-color: var(
            --color-background-brand-borderless-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-brand-borderless-action-button-active
        );
    }
}

/* Action buttons -- Info Intent */
.action-button-primary-info {
    color: var(--color-text-info-primary-action-button);
    background-color: var(--color-background-info-primary-action-button);

    &:hover {
        background-color: var(
            --color-background-info-primary-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-info-primary-action-button-active
        );
    }
}

.action-button-quiet-info {
    color: var(--color-text-info-quiet-action-button);
    background-color: var(--color-background-info-quiet-action-button);
    box-shadow: 0 0 0.5px 0.5px var(--color-inner-shadow-action-button) inset;

    &:hover {
        background-color: var(
            --color-background-info-quiet-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-info-quiet-action-button-active
        );
    }
}

.action-button-borderless-info {
    color: var(--color-text-info-borderless-action-button);
    background-color: transparent;

    &:hover {
        background-color: var(
            --color-background-info-borderless-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-info-borderless-action-button-active
        );
    }
}

/* Action buttons -- Success Intent */
.action-button-primary-success {
    color: var(--color-text-success-primary-action-button);
    background-color: var(--color-background-success-primary-action-button);

    &:hover {
        background-color: var(
            --color-background-success-primary-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-success-primary-action-button-active
        );
    }
}

.action-button-quiet-success {
    color: var(--color-text-success-quiet-action-button);
    background-color: var(--color-background-success-quiet-action-button);
    box-shadow: 0 0 0.5px 0.5px var(--color-inner-shadow-action-button) inset;

    &:hover {
        background-color: var(
            --color-background-success-quiet-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-success-quiet-action-button-active
        );
    }
}

.action-button-borderless-success {
    color: var(--color-text-success-borderless-action-button);
    background-color: transparent;

    &:hover {
        background-color: var(
            --color-background-success-borderless-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-success-borderless-action-button-active
        );
    }
}

/* Action buttons -- Warning Intent */
.action-button-primary-warning {
    color: var(--color-text-warning-primary-action-button);
    background-color: var(--color-background-warning-primary-action-button);

    &:hover {
        background-color: var(
            --color-background-warning-primary-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-warning-primary-action-button-active
        );
    }
}

.action-button-quiet-warning {
    color: var(--color-text-warning-quiet-action-button);
    background-color: var(--color-background-warning-quiet-action-button);
    box-shadow: 0 0 0.5px 0.5px var(--color-inner-shadow-action-button) inset;

    &:hover {
        background-color: var(
            --color-background-warning-quiet-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-warning-quiet-action-button-active
        );
    }
}

.action-button-borderless-warning {
    color: var(--color-text-warning-borderless-action-button);
    background-color: transparent;

    &:hover {
        background-color: var(
            --color-background-warning-borderless-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-warning-borderless-action-button-active
        );
    }
}

/* Action buttons -- Danger Intent */
.action-button-primary-danger {
    color: var(--color-text-danger-primary-action-button);
    background-color: var(--color-background-danger-primary-action-button);

    &:hover {
        background-color: var(
            --color-background-danger-primary-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-danger-primary-action-button-active
        );
    }
}

.action-button-quiet-danger {
    color: var(--color-text-danger-quiet-action-button);
    background-color: var(--color-background-danger-quiet-action-button);
    box-shadow: 0 0 0.5px 0.5px var(--color-inner-shadow-action-button) inset;

    &:hover {
        background-color: var(
            --color-background-danger-quiet-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-danger-quiet-action-button-active
        );
    }
}

.action-button-borderless-danger {
    color: var(--color-text-danger-borderless-action-button);
    background-color: transparent;

    &:hover {
        background-color: var(
            --color-background-danger-borderless-action-button-hover
        );
    }

    &:active {
        background-color: var(
            --color-background-danger-borderless-action-button-active
        );
    }
}

.icon-button {
    /* This class should always be used with an icon-button-* class
       defining the color scheme of the button, defined below. */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    font-size: var(--base-font-size-px);
    background-color: transparent; /* Override button default background color */
    padding: 0.375em;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    clip-path: inset(-1px);
    transition: 0.05s ease-in;
    transition-property: background-color, clip-path;

    &:hover {
        transition: 0.1s ease-out;
        transition-property: background-color, clip-path;
    }

    &:active {
        clip-path: inset(1px round 4px);
    }

    &:focus {
        /* Override common button outline style set in zulip.css and dark_theme.css */
        outline: none;
    }

    &:focus-visible {
        /* Override common button outline style set in zulip.css and dark_theme.css */
        outline: 1px solid var(--color-outline-focus) !important;
        outline-offset: 2px;
        clip-path: inset(-3px);
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon-button-neutral {
    color: var(--color-text-neutral-icon-button);

    &:hover {
        color: var(--color-text-neutral-icon-button-hover);
    }

    &:active {
        color: var(--color-text-neutral-icon-button-active);
    }

    &.icon-button-square {
        &:hover {
            background-color: var(
                --color-background-neutral-icon-button-square-hover
            );
        }

        &:active {
            color: var(--color-text-neutral-icon-button-square-active);
            background-color: var(
                --color-background-neutral-icon-button-square-active
            );
        }
    }
}

.icon-button-brand {
    color: var(--color-text-brand-icon-button);

    &:hover {
        color: var(--color-text-brand-icon-button-hover);
    }

    &:active {
        color: var(--color-text-brand-icon-button-active);
    }

    &.icon-button-square {
        &:hover {
            background-color: var(
                --color-background-brand-icon-button-square-hover
            );
        }

        &:active {
            color: var(--color-text-brand-icon-button-square-active);
            background-color: var(
                --color-background-brand-icon-button-square-active
            );
        }
    }
}

.icon-button-info {
    color: var(--color-text-info-icon-button);

    &:hover {
        color: var(--color-text-info-icon-button-hover);
    }

    &:active {
        color: var(--color-text-info-icon-button-active);
    }

    &.icon-button-square {
        &:hover {
            background-color: var(
                --color-background-info-icon-button-square-hover
            );
        }

        &:active {
            color: var(--color-text-info-icon-button-square-active);
            background-color: var(
                --color-background-info-icon-button-square-active
            );
        }
    }
}

.icon-button-success {
    color: var(--color-text-success-icon-button);

    &:hover {
        color: var(--color-text-success-icon-button-hover);
    }

    &:active {
        color: var(--color-text-success-icon-button-active);
    }

    &.icon-button-square {
        &:hover {
            background-color: var(
                --color-background-success-icon-button-square-hover
            );
        }

        &:active {
            color: var(--color-text-success-icon-button-square-active);
            background-color: var(
                --color-background-success-icon-button-square-active
            );
        }
    }
}

.icon-button-warning {
    color: var(--color-text-warning-icon-button);

    &:hover {
        color: var(--color-text-warning-icon-button-hover);
    }

    &:active {
        color: var(--color-text-warning-icon-button-active);
    }

    &.icon-button-square {
        &:hover {
            background-color: var(
                --color-background-warning-icon-button-square-hover
            );
        }

        &:active {
            color: var(--color-text-warning-icon-button-square-active);
            background-color: var(
                --color-background-warning-icon-button-square-active
            );
        }
    }
}

.icon-button-danger {
    color: var(--color-text-danger-icon-button);

    &:hover {
        color: var(--color-text-danger-icon-button-hover);
    }

    &:active {
        color: var(--color-text-danger-icon-button-active);
    }

    &.icon-button-square {
        &:hover {
            background-color: var(
                --color-background-danger-icon-button-square-hover
            );
        }

        &:active {
            color: var(--color-text-danger-icon-button-square-active);
            background-color: var(
                --color-background-danger-icon-button-square-active
            );
        }
    }
}

.button-loading-indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading_indicator_spinner {
        /* Override standard values defined
           in web/styles/app_components.css */
        height: 100%;
        width: unset;
        aspect-ratio: 1;

        & path {
            /* Set the loading indicator color
               to the font color of the button. */
            fill: currentcolor;
        }
    }
}

.button-hide-loading-indicator-on-hover:hover {
    .button-loading-indicator {
        visibility: hidden;
    }

    .action-button-label,
    .zulip-icon {
        visibility: visible !important;
    }
}
