/* CSS for Bootstrap typeahead */

.dropdown-menu {
    display: none;
    min-width: 160px;
    list-style: none;
}

.open > .dropdown-menu {
    display: block;
}

.typeahead {
    z-index: 1051;
}

.typeahead.dropdown-menu .typeahead-menu .simplebar-content {
    min-width: max-content;

    & > li {
        overflow-wrap: anywhere;

        & > a {
            display: flex;
            align-items: center;
            padding: 0.2142em 0.7142em; /* 3px 10px at 14px em */
            gap: 0.3571em; /* 5px at 14px em */
            font-weight: normal;
            /* We want to keep this `max-width` less than 320px. */
            max-width: 292px;
            line-height: 1.43; /* 20px / 14px */
            color: var(--color-dropdown-item);
            white-space: nowrap;

            @media (width >= $ml_min) {
                /* Scale up with font size on larger widths. */
                /* 292px / 14px */
                max-width: 20.86em;
            }

            /* hidden text just to maintain line height for a blank option */
            strong:empty {
                &::after {
                    content: ".";
                    visibility: hidden;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;
                outline: 0;
            }

            .user-circle {
                /* 11px at 16px/1em */
                font-size: 0.6875em;
                align-self: center;
                /* TODO: A grid rewrite of typeahead rows
                   should help to obviate these kinds of
                   fiddly spacing hacks. */
                margin-right: 2px;
                margin-left: -2px;
            }

            &.topic-typeahead-link {
                gap: 0;
            }

            &.topic-edit-typeahead {
                display: flex;
                flex-wrap: wrap;

                .typeahead-strong-section {
                    /* We want to wrap the topic but preserve
                       original white spaces sequence too. */
                    white-space: pre-wrap;
                }
            }

            .typeahead-text-container {
                display: flex;
                align-self: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                gap: 3px;
                align-items: baseline;
            }

            .compose-stream-name {
                overflow: visible;
                gap: 0;
            }
        }

        .stream-to-topic-arrow {
            margin-left: 0.375em; /* 6px at 16px em */
            cursor: default;
            color: var(--color-compose-chevron-arrow);
            text-decoration: none;
        }
    }

    .active > a {
        &,
        &:hover,
        &:focus {
            color: var(--color-active-dropdown-item);
            background-color: var(--background-color-active-typeahead-item);
        }
    }

    .pronouns,
    .autocomplete_secondary {
        align-self: baseline;
        opacity: 0.8;
        font-size: 85%;
        overflow: hidden;
        text-overflow: ellipsis;

        & > a {
            color: var(--color-dropdown-item);
            text-decoration: underline 1px;
            text-decoration-color: var(--color-dropdown-item-link-underline);
            opacity: 0.6;
        }
    }

    .autocomplete_secondary {
        flex: 1 1 0;
    }

    .active .pronouns,
    .active .autocomplete_secondary {
        opacity: 1;
    }
}

.typeahead.dropdown-menu {
    .typeahead-menu {
        list-style: none;
        margin: 4px 0;
        max-height: min(248px, 95vh);
        overflow-y: auto;
    }

    .typeahead-footer {
        margin: 0;
        padding: 4px 10px;
        border-top: 1px solid hsl(0deg 0% 0% / 20%);
        display: flex;
        align-items: center;
    }

    #typeahead-footer-text {
        color: var(--color-dropdown-item);
        font-size: 0.8571em; /* 12px at 14px/em */
    }

    a strong.typeahead-strong-section {
        /* Can't use flex to display this item if
           we want `text-overflow: ellipsis` to work
           which only works on block elements. */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        align-items: baseline;
        font-weight: 500;

        .stream-privacy-type-icon {
            margin-right: 3px;
        }
    }
}

.typeahead-option-label-container {
    display: flex !important;
    justify-content: space-between;

    > strong {
        margin-right: 14px;
    }

    .typeahead-option-label {
        color: var(--color-typeahead-option-label);
    }
}

/* For FontAwesome icons and zulip icons used in place of images for some users. */
.typeahead-image {
    font-size: 1.3571em; /* 19px at 14px em */
    display: inline-block;
    height: 1.1052em; /* 21px at 19px/1em */
    width: 1.1052em; /* 21px at 19px/1em */
    border-radius: 4px;

    text-align: center;

    &.zulip-icon-user-group {
        font-size: 1.3571em; /* 19px at 14px em */
    }

    &.no-presence-circle {
        /* 0.6875 * var(--base-font-size) is the width of presence
        circle, no margin (-2px left margin + 2px right margin = 0px)
        of the presence circle and 0.3571 * var(--base-font-size-px)
        is gap between the presence circle and avatar.
        Cannot directly use 0.6875em or 0.3571em
        since font-size for user group icon is different from the
        font size used to calculate presence circle width and gap. */
        margin-left: calc((0.6875 + 0.3571) * var(--base-font-size-px));
    }
}

.typeahead-text-container {
    i.zulip-icon-bot {
        align-self: center;
    }

    /*
    Negates the extra 3px left margin from `.status-emoji` in zulip.css, which,
    combined with .typeahead-text-container's `gap`, created unintended spacing.
    The typeahead's spacing is now managed only by `gap` in
    .typeahead-text-container.
    */
    .status-emoji {
        margin-left: 0;
    }
}
