:root {
    /* Width of emoji used by user to display status. */
    --user-status-emoji-width: 24px;
}

.right-sidebar {
    & a:hover {
        text-decoration: none;
    }
}

.right-sidebar-items {
    padding-left: var(--right-sidebar-padding-left);
}

.right-sidebar-title {
    color: var(--color-text-sidebar-heading);
    opacity: var(--opacity-sidebar-heading);
    font-size: inherit;
    font-weight: var(--font-weight-sidebar-heading);
    letter-spacing: var(--letter-spacing-sidebar-heading);
}

#buddy_list_wrapper {
    position: relative;
    margin-left: 0;
    overflow: auto;
}

.buddy-list-section-toggle.zulip-icon-heading-triangle-right {
    transition:
        opacity 140ms linear,
        rotate 140ms linear;

    &.rotate-icon-down {
        rotate: 90deg;
    }

    &.rotate-icon-right {
        rotate: 0deg;
    }
}

.buddy-list-section-toggle {
    grid-area: arrow;
    justify-self: center;
    color: var(--color-text-sidebar-heading);
    opacity: var(--opacity-sidebar-heading-icon);
}

.buddy-list-section-container {
    margin-bottom: 10px;

    &.no-display {
        display: none;
    }
}

.buddy-list-section-container.collapsed {
    .buddy-list-section,
    .buddy-list-user-link {
        display: none;
    }
}

.buddy-list-section .user_sidebar_entry,
#userlist-header {
    .user-list-sidebar-menu-icon {
        justify-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px 2px 2px 1px;
        /* This helps horizontally align the vdots,
           given the reduced margin-left above.
           Vertical centering looks better with an
           extra pixel of top padding in this area,
           too. */
        padding: 1px 0 0 1px;
        border-radius: 3px;

        & i {
            /* 17px at 16px em */
            font-size: 1.0625em;
            /* Ensure the icon takes the same baseline
               as the rest of the row, for perfect
               vertical alignment with the username and
               status circle. */
            line-height: inherit;
        }

        /*
        Hover does not work for touch-based devices like mobile phones.
        Hence the icons does not appear, making the user unaware of its
        presence on such devices. The following media property displays the
        icon by default for such behaviour.
        */

        @media (hover: none) {
            visibility: visible;
            /* Show dots on touchscreens in a less distracting,
               lighter shade. */
            color: var(--color-vdots-hint);
        }
    }

    &:hover {
        .user-list-sidebar-menu-icon {
            cursor: pointer;
            color: var(--color-vdots-visible);

            &:hover {
                color: var(--color-vdots-hover);
                background-color: var(--color-background-sidebar-action-hover);
            }
        }
    }
}

.buddy-list-section {
    margin: 0;
    overflow-x: hidden;
    list-style-position: inside; /* Draw the bullets inside our box */
    line-height: var(--line-height-sidebar-row);

    .user-list-sidebar-menu-icon {
        visibility: hidden;
    }

    li:hover {
        .user-list-sidebar-menu-icon {
            visibility: visible;
        }
    }

    & li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        list-style-type: none;
        border-radius: 4px;
        padding: 0;

        &:hover,
        &.narrow-filter:has(a.user-presence-link:focus-visible),
        &.highlighted_user {
            background-color: var(--color-buddy-list-highlighted-user);
            box-shadow: inset 0 0 0 1px var(--color-shadow-sidebar-row-hover);
        }

        &.narrow-filter:has(a.user-presence-link:focus-visible),
        &.highlighted_user {
            outline: 2px solid var(--color-outline-focus);
            outline-offset: -2px;
        }
    }

    & .narrow-filter {
        a.user-presence-link:focus {
            outline: none;
            text-decoration: none;
        }
    }

    .user-circle {
        grid-area: starting-anchor-element;
        place-self: center center;
        /* Tighten the line-height to match the icon's size... */
        line-height: 1;
        /* ...which is approximately 8px at 15px/1em in Vlad's design. */
        font-size: 0.5333em;
    }

    .user_sidebar_entry.with_avatar {
        .user-profile-picture-container {
            /* Establish positioning context for user circle. */
            position: relative;
            /* TODO: Express this as part of the grid on
               .selectable_sidebar_block. */
            margin-right: var(--right-sidebar-avatar-right-margin);

            &:not(:has(.user-circle-offline)) .user-profile-picture {
                /* The over-avatar user circle width is 15.5px at 20px/1em;
                   we adjust the border radius here to avoid any pixels
                   from the avatar bleeding through. */
                border-bottom-right-radius: 0.3875em; /* 7.75px at 20px/1em */
            }
        }

        .user-profile-picture {
            /* Push back on the margin-right usually allotted here;
               we put it on .user-profile-picture-container instead,
               so that user circles occupy the corner of the image. */
            margin-right: 0;
        }

        .user-circle {
            position: absolute;
            /* 10px at 16px/1em */
            font-size: 0.625em;
            bottom: -0.5px;
            right: -0.5px;
            background-color: var(--color-background);
            /* A 1.5px border provides better results than
               a 1px border, especially at smaller font sizes. */
            border: 1.5px solid var(--color-background);
            border-radius: 50%;

            &.user-circle-offline {
                display: none;
            }
        }
    }

    .empty-list-message {
        font-style: italic;
        color: var(--color-text-empty-list-message);
        /* Overwrite default empty list font size, to look better under the subheaders. */
        /* 14px at 16px/1em */
        font-size: 0.875em;
        /* Override .empty-list-message !important styling */
        padding: 0 !important;
        text-align: left;

        &:hover {
            /* Prevent hover styles set on other rows. */
            box-shadow: none;
            background-color: inherit;
        }
    }

    /* Overwrite some stray list rules (including one in left_sidebar.css) to turn color
       back to the bootstrap default. */
    .view-all-subscribers-link,
    .view-all-users-link {
        color: var(--color-text-url);

        &:hover {
            /* Prevent hover styles set on other rows until
               the right sidebar matches the action-heading typography
               of the left sidebar. */
            box-shadow: none;
            background-color: inherit;
            color: var(--color-text-url-hover);
        }
    }
}

.buddy-list-subsection-header {
    display: grid;
    align-items: center;
    grid-template:
        "arrow row-content scroll-buffer" var(
            --line-height-sidebar-row-prominent
        )
        / var(--right-sidebar-header-icon-toggle-width) minmax(0, 1fr);
    cursor: pointer;
    background-color: var(--color-background);
    position: sticky;
    top: 0;
    z-index: 1;
    color: var(--color-text-default);
    border-radius: 4px;
    margin-right: var(--width-simplebar-scroll-hover);

    &:hover {
        background-color: var(--color-buddy-list-highlighted-user);
        box-shadow: inset 0 0 0 1px var(--color-shadow-sidebar-row-hover);

        .buddy-list-section-toggle,
        .buddy-list-heading {
            opacity: var(--opacity-sidebar-heading-hover);
        }
    }
}

.buddy-list-heading {
    user-select: none;
    margin: 0;
    padding: 5px 5px 5px 0;
    color: var(--color-text-sidebar-heading);
    font-size: inherit;
    font-weight: var(--font-weight-sidebar-heading);
    letter-spacing: var(--letter-spacing-sidebar-heading);
    opacity: var(--opacity-sidebar-heading);
    transition: opacity 140ms linear;
    grid-area: row-content;
    display: flex;
}

.buddy-list-heading-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 2px;
}

.buddy-list-heading-user-count-with-parens {
    opacity: 0.75;
}

.buddy-list-subsection-header.no-display {
    display: none;
}

.user-presence-link,
.user_sidebar_entry .selectable_sidebar_block {
    overflow: hidden;
    color: var(--color-text-sidebar-row);

    .user-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry .selectable_sidebar_block {
    grid-area: row-content;
    display: grid;
    grid-template:
        "starting-anchor-element row-content markers-and-controls" var(
            --line-height-sidebar-row
        )
        ".                       row-content ." auto / var(
            --right-sidebar-presence-circle-width
        )
        minmax(0, 1fr)
        minmax(0, auto);
    align-items: baseline;
}

.user_sidebar_entry.with_avatar {
    grid-template:
        "row-content" var(--line-height-sidebar-row-with-avatars)
        "row-content" auto / minmax(0, 1fr);

    .selectable_sidebar_block {
        margin: 4px;
        margin-left: calc(var(--right-sidebar-header-icon-toggle-width) / 2);
    }

    .avatar-preload-background {
        background-color: var(--color-buddy-list-avatar-loading);
    }

    .unread_count:not(.hide) {
        margin-right: 2px;
    }

    &.with_status .unread_count {
        align-self: baseline;
    }
}

.user-presence-link {
    grid-area: row-content;

    &:hover,
    &:focus {
        color: var(--color-text-sidebar-row);
        text-decoration: none;
    }
}

.information-settings .profile-with-avatar,
.user_sidebar_entry.with_avatar .selectable_sidebar_block {
    line-height: var(--line-height-sidebar-row-with-avatars);
    display: grid;
    grid-template:
        "avatar row-content markers-and-controls" var(
            --right-sidebar-avatar-width
        )
        / auto minmax(0, 1fr) minmax(0, auto);
    justify-content: flex-start;
    align-items: center;
}

.information-settings .profile-with-avatar {
    margin: 5px 0;
}

.my_user_status {
    opacity: 0.5;
    white-space: nowrap;
}

.selectable_sidebar_block {
    cursor: pointer;
}

.user_list_style_values {
    .user-name-and-status-emoji {
        display: flex;
        align-items: center;
        width: 100%;

        .user-name {
            display: inline-block;
            max-width: calc(100% - var(--user-status-emoji-width));
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .status-emoji {
            line-height: 20px;
            margin-left: 6px;
        }
    }

    .status-text {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry {
    display: grid;
    /* We establish a two-row, two-column outer grid so
       that controls remain aligned with the username,
       even when there is a status line shown below.

       The 25px column for the vdots is less than the
       30px allotted in the left sidebar, but it holds
       the username area constant, so that no ellipsis
       appears on the username on hover. The 25px value
       is necessary for correct vdots alignment with the
       filter row's vdots. */
    grid-template:
        "row-content ending-anchor-element" var(--line-height-sidebar-row)
        "row-content ." auto / minmax(0, 1fr) var(--right-sidebar-vdots-width);
    align-content: baseline;
    margin-right: var(--width-simplebar-scroll-hover);
    /* When both the left circle and three dot menu are present, we want
       the space to the left of the circle to be more similar to the space
       to the right of the three dot menu. */
    &:not(.with_avatar) {
        padding-left: calc(var(--right-sidebar-toggle-width-offset) - 2px);
    }

    &:hover {
        cursor: pointer;
    }

    .user-name-and-status-emoji {
        display: flex;
    }

    .status-text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.75;
        font-size: 90%;
    }

    & span.status-text:not(:empty) {
        /* Cinch up the status text by one quarter of the
           sidebar row's line-height. */
        margin-top: calc((var(--line-height-sidebar-row) / 4) * -1);
    }

    .unread_count {
        grid-area: markers-and-controls;
        align-self: center;
        display: none;
    }

    .unread_count:not(.hide) {
        display: block;
        margin-left: 4px;

        &:empty {
            /* Hide otherwise empty unread count pill
               after DMs have been read, but before the
               count has been removed from the DOM. */
            display: none;
        }
    }
}

#userlist-toggle {
    text-align: center;
    vertical-align: middle;
}

#userlist-toggle-button {
    text-decoration: none;
    color: hsl(0deg 0% 60%);

    &:hover {
        color: inherit;
    }
}

.right-sidebar-items:first-of-type #userlist-header {
    border-top: none;
}

#userlist-header {
    cursor: pointer;
    display: grid;
    grid-template-rows: var(--line-height-sidebar-row-prominent);
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: center;
    padding-bottom: 10px;
    padding-left: var(--right-sidebar-heading-left-spacing);
    /* The scrollbar doesn't extend this high, but we want the three-dot
       menus to line up. */
    padding-right: var(--width-simplebar-scroll-hover);
    background-color: var(--color-background);

    #userlist-header-search {
        display: grid;
        grid-template-rows: var(--line-height-sidebar-row-prominent);
        grid-template-columns: minmax(0, 1fr) 30px;
        align-items: center;

        & .user-list-filter {
            grid-area: 1 / 1 / 2 / 3;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            /* Prevent text from colliding with #clear_search_button */
            padding-right: 28px;
            height: var(--line-height-sidebar-row-prominent);
            box-sizing: border-box;
        }

        .user-list-filter:placeholder-shown + #clear_search_people_button {
            visibility: hidden;
        }
    }

    #buddy-list-menu-icon {
        color: var(--color-vdots-visible);
        justify-content: center;
        display: grid;
        width: var(--right-sidebar-vdots-width);
        margin-left: 5px;
        /* Push back against default right-sidebar
           spacing for better vdots alignment. */
        margin-right: 0;
        padding-left: 0;

        &:hover {
            color: var(--color-vdots-hover);
        }
    }
}

.buddy-list-user-link,
.invite-user-shortcut {
    margin-right: var(--width-simplebar-scroll-hover);
    border-radius: 4px;

    &:hover {
        background: var(--color-background-sidebar-action-heading-hover);
        box-shadow: inset 0 0 0 1px var(--color-shadow-sidebar-row-hover);
        color: var(--color-text-sidebar-action-heading-hover);
    }

    .right-sidebar-wrappable-text-container {
        display: grid;
        grid-template-rows: minmax(
            var(--line-height-sidebar-row-prominent),
            auto
        );
        align-items: center;
        color: var(--color-text-sidebar-action-heading);

        .right-sidebar-wrappable-text-inner {
            margin: var(--spacing-top-bottom-sidebar-topic-inner) 0;
            line-height: 1;
            text-decoration: none;
            font-size: var(--font-size-sidebar-action-heading);
            font-weight: var(--font-weight-sidebar-action-heading);
            font-variant: var(--font-variant-sidebar-action-heading);
            font-feature-settings: var(
                --font-feature-settings-sidebar-action-heading
            );
        }
        text-transform: var(--text-transform-sidebar-action-heading);
    }
}

#user-list.with_avatars
    .buddy-list-user-link
    .right-sidebar-wrappable-text-container,
#user-list.with_avatars .buddy-list-section .empty-list-message {
    margin-left: var(--right-sidebar-text-indent-with-avatar);
}

.buddy-list-user-link .right-sidebar-wrappable-text-container,
.buddy-list-section .empty-list-message {
    margin-left: var(--right-sidebar-text-indent-without-avatar);
}

.invite-user-shortcut {
    /* The margin top is calculated from a legacy 25px height,
       from a 20px line of text and 5px of margin top. We calculate
       a scaling margin-top by subtracting the em-unit line height
       from the legacy value. */
    margin-top: calc(25px - (var(--legacy-body-line-height-unitless) * 1em));
    margin-bottom: var(--sidebar-bottom-spacing);

    .invite-user-link {
        /* TODO: We should eventually change the grid to use the correct
           --right-sidebar-presence-circle-width with left spacing, and
           share that left spacing value here. */
        padding-left: calc(var(--right-sidebar-toggle-width-offset) + 0.3em);
    }
}

#user-list.with_avatars .invite-user-link {
    padding-left: calc(var(--right-sidebar-header-icon-toggle-width) / 2);
}

#buddy-list-actions-menu-popover {
    .display-style-selector + .invite-user-link-item {
        border-top: 1px solid var(--color-border-sidebar-subheader);
    }

    /* No hover effect on the label */
    .display-style-selector-header:hover {
        background: inherit;
        cursor: default;
    }

    .popover-menu-link:not(.display-style-selector-header) {
        display: grid;
        grid-template: "left-icon row-content" auto / 18px minmax(
                max-content,
                1fr
            );
        align-items: center;

        .popover-menu-icon,
        .user_list_style_choice {
            grid-area: left-icon;
            justify-self: baseline;
        }

        .popover-menu-label {
            grid-area: row-content;
        }
    }
}
